@tailwind components;
@tailwind utilities;
:root {
  font-family: "Poppins", sans-serif;
  color: #221122;
}

* {
  scroll-behavior: smooth;
}

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
main,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  padding: 0;
  margin: 0;
  border: 0;
}

button:focus {
  outline: none !important;
}

button,
input[type="submit"],
input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.text-64-light,
.text-64-regular,
.text-64-medium,
.text-64-semibold,
.text-64-bold,
.text-48-light,
.text-48-regular,
.text-48-medium,
.text-48-semibold,
.text-48-bold,
.text-36-light,
.text-36-regular,
.text-36-medium,
.text-36-semibold,
.text-36-bold,
.text-24-light,
.text-24-regular,
.text-24-medium,
.text-24-semibold,
.text-24-bold,
.text-18-light,
.text-18-regular,
.text-18-medium,
.text-18-semibold,
.text-18-bold,
.text-16-light,
.text-16-regular,
.text-16-medium,
.text-16-semibold,
.text-16-bold,
.text-14-light,
.text-14-regular,
.text-14-medium,
.text-14-semibold,
.text-14-bold,
.text-12-light,
.text-12-regular,
.text-12-medium,
.text-12-semibold,
.text-12-bold {
  font-family: "Poppins", sans-serif;
}

.code {
  font-family: "Space Mono", monospace;
}

.fade-in-image {
  animation: fadeIn 2s infinite;
}
@keyframes fadeIn {
  0% {
    opacity: 0.65;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.65;
  }
}

.box-shadow {
  box-shadow: 0 2px 12px #cadde8;
}

.box-shadow:hover {
  box-shadow: 0 2px 12px #cadde8;
  transition: 0.3s;
}